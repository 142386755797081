import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Footer from 'containers/AppMinimal/Footer';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
    GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';
import Navbar from 'containers/AppMinimal/Navbar';

const PrivacyPolicy = () => {


    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Terms & Conditions"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="black-sticky noHarp">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <section style={{background: "#1089ff", width: "100%", height: "100px"}}></section>
                        <section style={{width: "80%", margin: "auto", textAlign: "justify", paddingTop: "80px"}}>
                            <h1>Privacy Policy</h1>
                            <p>Welcome to OnwardTicketPro – onwardticketpro.com (the “Site”). We recognize the
                                importance of privacy for users of our Site, especially when conducting business. This
                                statement outlines our privacy policies regarding users who visit the Site without
                                conducting business and users who register to use our Services (“Authorized
                                Customers”).</p>

                            <h2>Definition of Personally Identifiable Information</h2>
                            <p>“Personally Identifiable Information” refers to information that can identify, contact,
                                or locate a specific person, including but not limited to, name, address, phone number,
                                fax number, email address, financial profiles, social security number, and credit card
                                information. It does not include information collected anonymously or demographic
                                information not linked to an identified individual.</p>

                            <h2>Collection of Personally Identifiable Information</h2>
                            <p>We collect basic user profile information from all Visitors. For Authorized Customers, we
                                collect additional information such as name, email address, and details regarding the
                                nature and size of the advertising inventory intended for purchase or sale.</p>

                            <h2>Information Collection by Third Parties</h2>
                            <p>Besides our direct collection of information, third-party service vendors (e.g., credit
                                card companies, banks) may collect information from our Visitors and Authorized
                                Customers. These third parties may provide services such as credit, insurance, and
                                escrow. While we do not control how these third parties use such information, we ask
                                them to disclose their usage practices. Some third parties may act as intermediaries and
                                do not store, retain, or use the information provided to them.</p>

                            <h2>Use of Personally Identifiable Information</h2>
                            <p>We use Personally Identifiable Information to customize the Site, make service offerings,
                                and fulfill requests for buying and selling on the Site. We may contact Visitors and
                                Authorized Customers regarding research, purchase, and selling opportunities or provide
                                information related to the Site's subject matter. We also respond to specific inquiries
                                using this information.</p>

                            <h2>Sharing of Personally Identifiable Information</h2>
                            <p>Personally Identifiable Information about Authorized Customers may be shared with other
                                Authorized Customers evaluating potential transactions. We may also share aggregated
                                demographic information about our Visitors and Authorized Customers with affiliated
                                agencies and third-party vendors. Visitors and Authorized Customers can opt out of
                                receiving information or being contacted by us or our affiliated agencies by following
                                instructions provided in emails or contacting us directly.</p>

                            <h2>Storage of Personally Identifiable Information</h2>
                            <p>Personally Identifiable Information collected by OnwardTicketPro is securely stored and
                                is only accessible to third parties or employees of OnwardTicketPro as necessary for the
                                purposes mentioned above.</p>

                            <h2>Visitor Choices Regarding Information Collection and Use</h2>
                            <p>Visitors and Authorized Customers can opt out of receiving unsolicited information from
                                us or being contacted by our vendors and affiliated agencies by following the
                                instructions in emails or contacting us.</p>

                            <h2>Cookies</h2>
                            <p>Cookies are strings of information stored on a visitor’s computer that the visitor’s
                                browser provides to the website each time the visitor returns. We use cookies to collect
                                information and to understand visitor preferences and selected services. You can
                                configure your browser to refuse cookies, but this may limit the functionality of some
                                parts of our Service.</p>

                            <h2>Use of Cookies by Service Providers</h2>
                            <p>Our service providers may use cookies, which could be stored on your computer when
                                visiting our Site. More details about the cookies used can be found on our cookies info
                                page.</p>

                            <h2>Use of Login Information</h2>
                            <p>OnwardTicketPro uses login information, such as IP addresses, ISPs, browser types,
                                browser versions, pages visited, and the date and time of visits, to analyze trends,
                                administer the Site, track user movements, and gather broad demographic information.</p>

                            <h2>Access to Personally Identifiable Information by Partners and Service Providers</h2>
                            <p>OnwardTicketPro partners with various vendors who may access Personally Identifiable
                                Information to evaluate Authorized Customers for service eligibility. Our privacy policy
                                does not cover their collection or use of this information.</p>

                            <h2>Security of Personally Identifiable Information</h2>
                            <p>We have implemented security measures to protect the Personally Identifiable Information
                                of our Visitors and Authorized Customers. This includes encryption protocols for
                                sensitive information like credit card numbers and social security numbers. However,
                                electronic communications and databases can be subject to errors, tampering, and
                                break-ins, and we cannot guarantee complete security. We will not be liable for such
                                occurrences.</p>

                            <h2>Correction of Inaccuracies in Personally Identifiable Information</h2>
                            <p>Visitors and Authorized Customers can contact us to update or correct inaccuracies in
                                their Personally Identifiable Information by emailing us.</p>

                            <h2>Deletion or Deactivation of Personally Identifiable Information</h2>
                            <p>Visitors and Authorized Customers can request the deletion or deactivation of their
                                Personally Identifiable Information by contacting us. However, due to backups and
                                records of deletions, some residual information may remain. Deactivated information will
                                be functionally deleted, and we will not use or transfer it moving forward.</p>

                            <h2>Your Rights Under Data Protection Law</h2>
                            <p>You have the following rights:</p>
                            <ul>
                                <li>The right to access</li>
                                <li>The right to rectification</li>
                                <li>The right to erasure</li>
                                <li>The right to restrict processing</li>
                                <li>The right to object to processing</li>
                                <li>The right to data portability</li>
                                <li>The right to complain to a supervisory authority</li>
                                <li>The right to withdraw consent</li>
                            </ul>

                            <h2>Children’s Privacy</h2>
                            <p>Our Service does not address anyone under the age of 18 (“Children”), and we do not
                                knowingly collect personally identifiable information from children under 18. If you are
                                a parent or guardian and become aware that your child has provided us with personal
                                information, please contact us immediately. If we discover that a child under 18 has
                                provided us with personal information, we will delete such information from our
                                servers.</p>

                            <h2>Compliance with Laws</h2>
                            <p>We will disclose Personally Identifiable Information to comply with legal requirements,
                                such as court orders or subpoenas, or requests from law enforcement agencies. We will
                                also disclose such information when necessary to protect the safety of our Visitors and
                                Authorized Customers.</p>

                            <h2>Changes to the Privacy Policy</h2>
                            <p>We will notify Visitors and Authorized Customers about changes to our privacy policy by
                                posting updates on the Site. If changes could lead to the disclosure of Personally
                                Identifiable Information that a Visitor or Authorized Customer previously requested not
                                to be disclosed, we will contact the individual to prevent such disclosure.</p>

                            <h2>Links</h2>
                            <p>OnwardTicketPro.com contains links to other websites. When you click on one of these
                                links, you are moving to another website. We encourage you to read the privacy
                                statements of these linked sites, as their privacy policies may differ from ours.</p>
                        </section>
                    </ContentWrapper>
                    <GradientWrapper>
                        <div style={{'height': '100px'}}></div>
                        <Footer/>
                    </GradientWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default PrivacyPolicy;
